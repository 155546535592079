<template>
  <v-card class="card-form-webengine">
    <v-form data-vv-scope="form-webengine" data-testid="form-webengine">
      <v-card-text class="pa-5">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              ref="name"
              v-model="activeWebEngine.name"
              :error-messages="errors.collect('form-webengine.name')"
              label="Event name"
              placeholder=""
              data-vv-name="name"
              data-vv-validate-on="change"
              data-vv-as="Event name"
              outlined
              single-line
              v-validate="'required|max:50'"
              dense
              :autofocus="haveEngineNameComputed"
              data-testid="name"
            />
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <!-- :error-messages="errors.collect('form-webengine.link')" -->
            <!-- data-vv-name="name"
            data-vv-validate-on="change"
            v-validate="'required|max:50'" -->
            <v-text-field
              ref="guestLink"
              v-model="getGuestLink"
              label="Guest link"
              placeholder=""
              outlined
              single-line
              readonly
              :messages="
                !canGenerateEngineLinkComputed
                  ? 'Old link will be disabled'
                  : 'To generate link need to save this engine '
              "
              id="web-engine-generated-link"
              dense
              :disabled="canGenerateEngineLinkComputed || generateLinkLoading"
              data-testid="guest-link"
            >
              <template
                v-slot:append-outer
                v-if="!canGenerateEngineLinkComputed"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      @click.stop.prevent="copyToClipboard"
                      :disabled="generateLinkLoading"
                      style="height: 28px; width: 28px"
                      data-testid="btn-copy-guest-link"
                    >
                      <v-icon v-on="on">mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  Copy to clipboard
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="3" class="text-center text-md-left">
            <!-- :large="!$vuetify.breakpoint.smAndDown" :x-large="$vuetify.breakpoint.mdAndUp"  -->
            <v-btn
              @click="generateLink(editedIndex)"
              dark
              block
              color="primary"
              :disabled="canGenerateEngineLinkComputed"
              :loading="generateLinkLoading"
              data-testid="btn-generate-guest-link"
            >
              Generate new link
              <template v-slot:loader>
                <span data-testid="loading-generate-guest-link">
                  Loading...
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <!-- Select reseiving stream START -->
          <v-col
            cols="12"
            md="6"
            class="pr-md-4"
            data-testid="container-remote-stream"
          >
            <div class="body-2 pa-2" data-testid="title-remote-stream">
              Select remote stream
            </div>
            <v-card
              id="remote-preview-container"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'min-height: 260px; max-height: 260px;'
                  : 'min-height: 360px; max-height: 360px;'
              "
              class="mb-1"
              data-testid="block-remote-stream-not-started"
            >
              <v-card-text
                v-show="!isPreviewRemoteStreamStarted"
                id="remote-preview-block"
                class="pa-0"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'height: 260px;'
                    : 'height: 360px;'
                "
              >
                <div
                  class="subheading pa-4"
                  data-testid="title-remote-stream-not-started"
                >
                  Preview remote stream not started
                </div>

                <v-snackbar
                  :timeout="errorsRemoteStream.timeout"
                  :value="errorsRemoteStream.show"
                  absolute
                  bottom
                  left
                  class="full-width-snackbar"
                  color="transparent"
                >
                  <v-alert
                    :model="errorsRemoteStream.show"
                    border="left"
                    :color="errorsRemoteStream.color"
                    style="
                      position: absolute;
                      bottom: 0;
                      right: 0;
                      width: 100%;
                      margin-bottom: 0;
                    "
                    text
                    outlined
                    data-testid="errors-remote-stream-not-started"
                  >
                    <!-- eslint-disable eslint-disable-next-line vue/no-unused-vars -->
                    <template v-slot:close="{ toggle }">
                      <v-icon
                        dark
                        small
                        :color="errorsRemoteStream.color"
                        @click="
                          $store.dispatch('saveRemoteStreamErrors', {
                            show: false,
                          })
                        "
                        >close
                      </v-icon>
                    </template>
                    {{ errorsRemoteStream.text }}
                  </v-alert>
                </v-snackbar>
              </v-card-text>
              <v-card-text
                v-show="isPreviewRemoteStreamStarted"
                id="remote-preview-block-active"
                class="pa-0 video-container"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'height: 260px;'
                    : 'height: 360px;'
                "
                data-testid="block-remote-stream-started"
              >
                <video
                  id="received_video"
                  autoplay
                  :controls="false"
                  :webkit-playsinline="true"
                  :playsinline="true"
                  data-testid="video-remote-stream"
                />
              </v-card-text>
            </v-card>

            <v-card
              class="outlined-card mt-4"
              data-testid="controls-remote-stream-started"
            >
              <v-card-title
                class="body-2"
                data-testid="title-remote-stream-started"
              >
                Remote controls
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-select
                      outlined
                      v-model="activeWebEngine.remote.stream"
                      :items="webengineSendersSignal"
                      item-text="nickName"
                      return-object
                      :menu-props="{ maxHeight: '132' }"
                      label="Available aspx stream"
                      :hint="
                        roomMembers.senders.length !== 0 ||
                        !canGenerateEngineLinkComputed
                          ? 'Available streams'
                          : 'To connect need to save this engine or start streaming'
                      "
                      persistent-hint
                      @change="startPreviewAspxStream()"
                      dense
                      data-testid="select-remote-streams-list"
                    />
                  </v-col>
                  <!--:disabled="roomMembers.senders.length === 0"-->

                  <v-col cols="12" sm="12">
                    <RemoteAudioChannels />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Select reseiving stream END -->

          <!-- Select send stream START -->
          <v-col
            cols="12"
            md="6"
            class="pl-md-4"
            data-testid="container-local-stream"
          >
            <div class="body-2 pa-2" data-testid="title-local-stream">
              Setup your stream
            </div>
            <v-card
              id="local-preview-container"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'min-height: 260px; max-height: 260px;'
                  : 'min-height: 360px; max-height: 360px;'
              "
              class="mb-1"
            >
              <v-card-text
                v-show="!isLocalStreamStarted"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'height: 260px;'
                    : 'height: 360px;'
                "
                data-testid="block-local-stream-not-started"
              >
                <div class="subheading">Webcamera stream not started</div>
                <v-btn
                  dark
                  large
                  color="green darken-3"
                  v-if="!noSourceToStart"
                  @click="startLocalStream()"
                  class="btn-play-local-stream"
                  data-testid="btn-play-local-stream"
                >
                  <v-icon dark>mdi-play</v-icon>
                </v-btn>

                <!--                <v-alert-->
                <!--                  border="left"-->
                <!--                  color="error"-->
                <!--                  dismissible-->
                <!--                  type="error"-->
                <!--                  style="position:absolute; bottom:0;right:0;width:100%;margin-bottom:0;"-->
                <!--                >Preview remote stream not started-->
                <!--                </v-alert>-->

                <v-snackbar
                  :timeout="errorsLocalStream.timeout"
                  :value="errorsLocalStream.show"
                  absolute
                  bottom
                  left
                  class="full-width-snackbar"
                  color="transparent"
                >
                  <v-alert
                    :model="errorsLocalStream.show"
                    border="left"
                    :color="errorsLocalStream.color"
                    style="
                      position: absolute;
                      bottom: 0;
                      right: 0;
                      width: 100%;
                      margin-bottom: 0;
                    "
                    text
                    outlined
                    data-testid="errors-local-stream-not-started"
                  >
                    <!-- eslint-disable eslint-disable-next-line vue/no-unused-vars -->
                    <template v-slot:close="{ toggle }">
                      <v-icon
                        dark
                        small
                        :color="errorsLocalStream.color"
                        @click="
                          $store.dispatch('saveLocalStreamErrors', {
                            show: false,
                          })
                        "
                        >close
                      </v-icon>
                    </template>
                    {{ errorsLocalStream.text }}
                  </v-alert>
                </v-snackbar>
              </v-card-text>
              <v-card-text
                v-show="isLocalStreamStarted"
                class="pa-0 video-container"
                id="local_video_no_stream"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'height: 260px;'
                    : 'height: 360px;'
                "
                data-testid="block-local-stream-started"
              >
                <audio
                  v-show="!mediaConstraints.video && mediaConstraints.audio"
                  id="local_audio"
                  controls
                  autoplay
                  data-testid="audio-local-stream"
                />
                <video
                  v-show="
                    mediaConstraints.video ||
                    (!mediaConstraints.video && !mediaConstraints.audio)
                  "
                  id="local_video"
                  autoplay
                  :controls="false"
                  :webkit-playsinline="true"
                  :playsinline="true"
                  data-testid="video-local-stream"
                />
                <v-btn
                  dark
                  large
                  color="orange darken-3"
                  class="btn-stop-local-stream"
                  @click="stopLocalStream()"
                  data-testid="btn-stop-local-stream"
                >
                  <v-icon dark>mdi-stop</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>

            <v-card
              class="outlined-card mt-4"
              id="local-preview-options"
              data-testid="controls-local-stream-started"
            >
              <v-card-title
                class="body-2"
                data-testid="title-local-stream-started"
              >
                My video controls
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      v-model="selectedVideoSource"
                      :items="allVideoSources"
                      item-text="text"
                      item-value="deviceId"
                      :menu-props="{ maxHeight: '132' }"
                      label="Video source"
                      hint="Video source"
                      persistent-hint
                      :error-messages="
                        errors.collect('form-webengine.selectedVideoSource')
                      "
                      data-vv-name="selectedVideoSource"
                      :disabled="
                        modelWebengine.localStreamTypeSelected ==
                        'type_webcamera'
                          ? false
                          : true
                      "
                      @change="runAllStreams"
                      dense
                      data-testid="select-local-video-source"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="startWebrtcScanDevices()"
                          class="btn-rescan-select red accent-2 text-uppercase"
                          data-testid="btn-rescan-local-sources-video"
                        >
                          <v-list-item-action>
                            <v-icon color="white">
                              mdi-monitor-cellphone-star
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Re-Scan devices
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      v-model="selectedAudioSource"
                      :items="allAudioSources"
                      item-text="text"
                      item-value="deviceId"
                      :menu-props="{ maxHeight: '400' }"
                      label="Audio source"
                      hint="Audio source"
                      persistent-hint
                      :error-messages="
                        errors.collect('form-webengine.selectedAudioSource')
                      "
                      data-vv-name="audio_deviceid"
                      @change="runAllStreams"
                      dense
                      data-testid="select-local-audio-source"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="startWebrtcScanDevices()"
                          class="btn-rescan-select red accent-2 text-uppercase"
                          data-testid="btn-rescan-local-sources-audio"
                        >
                          <v-list-item-action>
                            <v-icon color="white">
                              mdi-monitor-cellphone-star
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Re-Scan devices
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      v-model="selectedResolution"
                      :items="listWebcameraResolutions"
                      item-text="name"
                      :menu-props="{ maxHeight: '400' }"
                      label="Webcamera resolution"
                      :hint="resolutionString"
                      return-object
                      persistent-hint
                      :error-messages="
                        errors.collect('form-webengine.selectedResolution')
                      "
                      data-vv-name="video_resolution"
                      @change="onChangeResolution($event)"
                      dense
                      data-testid="select-local-resolution"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Select send stream END -->

          <!-- Advanced Settings Start -->
          <v-col
            cols="12"
            md="12"
            data-testid="container-common-webengine-settings"
          >
            <v-expansion-panels v-model="panel" :readonly="false" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>Settings</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="8">
                          <div class="d-inline-flex align-start flex-wrap">
                            <!--                            <div>-->
                            <!--                              <v-slider-->
                            <!--                                v-model="activeWebEngine.source.min_framerate"-->
                            <!--                                :min="0"-->
                            <!--                                :max="getMinFramerateRanges('max')"-->
                            <!--                                label="Min frameRate"-->
                            <!--                                :disabled="modelWebengine.localStreamTypeSelected == 'type_webcamera' ? false : true"-->
                            <!--                                style="display: none;"-->
                            <!--                              ></v-slider>-->
                            <!--                              <v-text-field-->
                            <!--                                outlined-->
                            <!--                                label="Min"-->
                            <!--                                v-model="activeWebEngine.source.min_framerate"-->
                            <!--                                :min="0"-->
                            <!--                                :max="getMinFramerateRanges('max')"-->
                            <!--                                class="mx-2"-->
                            <!--                                type="number"-->
                            <!--                                suffix="fps"-->
                            <!--                                :disabled="modelWebengine.localStreamTypeSelected == 'type_webcamera' ? false : true"-->
                            <!--                                dense-->
                            <!--                                style="max-width: 120px;"-->
                            <!--                              ></v-text-field>-->
                            <!--                            </div>-->
                            <!--                            <div>-->
                            <!--                              <v-slider-->
                            <!--                                v-model="activeWebEngine.source.max_framerate"-->
                            <!--                                :min="0"-->
                            <!--                                :max="60"-->
                            <!--                                label="Max frameRate"-->
                            <!--                                style="display: none;"-->
                            <!--                              ></v-slider>-->
                            <!--                              <v-text-field-->
                            <!--                                outlined-->
                            <!--                                label="Max"-->
                            <!--                                v-model="activeWebEngine.source.max_framerate"-->
                            <!--                                :min="0"-->
                            <!--                                :max="60"-->
                            <!--                                class="mx-2"-->
                            <!--                                type="number"-->
                            <!--                                suffix="fps"-->
                            <!--                                dense-->
                            <!--                                style="max-width: 120px;"-->
                            <!--                              ></v-text-field>-->
                            <!--                            </div>-->
                            <div>
                              <v-select
                                outlined
                                v-model="selectedSignal"
                                :items="signalServersList"
                                item-text="data.name"
                                item-value="_key"
                                :menu-props="{
                                  maxHeight: '132',
                                  maxWidth: '300',
                                }"
                                class="mx-2"
                                label="Signal server"
                                hint="Signal server"
                                persistent-hint
                                :error-messages="
                                  errors.collect(
                                    'form-webengine.selectedSignal'
                                  )
                                "
                                data-vv-name="selectedSignal"
                                v-validate="'required'"
                                dense
                                data-testid="signal-server"
                              />
                            </div>

                            <div>
                              <v-checkbox
                                v-model="activeWebEngine.common_room"
                                label="Use common room"
                                class="mx-2 mt-1"
                                dense
                                data-testid="chbx-common-room"
                              />
                            </div>

                            <div>
                              <v-checkbox
                                v-model="useJanus"
                                label="Use Janus"
                                class="mx-2 mt-1"
                                dense
                                data-testid="chbx-use-janus"
                              />
                            </div>

                            <fieldset
                              class="d-inline-flex align-start flex-wrap fieldset-settings mx-2"
                              data-testid="container-your-stream-settings"
                            >
                              <legend
                                class="legend-settings"
                                data-testid="title-your-stream-settings"
                              >
                                Your stream settings
                              </legend>
                              <div>
                                <v-select
                                  outlined
                                  v-model="activeWebEngine.source.video_codec"
                                  :items="modelWebengine.local.listVideoCodecs"
                                  :menu-props="{
                                    maxHeight: '132',
                                    maxWidth: '300',
                                  }"
                                  item-text="name"
                                  item-value="_key"
                                  class="mx-2 pb-3"
                                  label="Video codec"
                                  hint="Sender video codec"
                                  persistent-hint
                                  :error-messages="
                                    errors.collect(
                                      'form-webengine.selectedSenderVideoCodec'
                                    )
                                  "
                                  data-vv-name="selectedSenderVideoCodec"
                                  dense
                                  v-validate="'required'"
                                  style="max-width: 140px"
                                  data-testid="video-codec"
                                />
                              </div>
                              <div>
                                <v-text-field
                                  v-model="activeWebEngine.remote.bandwidth"
                                  outlined
                                  label="Bandwidth"
                                  hint="Max bandwidth"
                                  persistent-hint
                                  :error-messages="
                                    errors.collect('form-webengine.bandwidth')
                                  "
                                  data-vv-name="bandwidth"
                                  class="mx-2 pb-3"
                                  v-validate="
                                    'isNumerical|min_value:0|max_value:1000'
                                  "
                                  data-vv-as="Bandwidth"
                                  dense
                                  style="max-width: 140px"
                                  suffix="MBits"
                                  data-testid="bandwidth"
                                />
                              </div>
                              <div>
                                <v-combobox
                                  v-model="activeWebEngine.source.maxFramerate"
                                  :items="maxFramerateItems"
                                  outlined
                                  label="Max frame rate"
                                  class="mx-2 pb-3 active-label"
                                  suffix="fps"
                                  dense
                                  style="max-width: 180px"
                                  persistent-hint
                                  hint="Peer max framerate"
                                  :error-messages="
                                    errors.collect(
                                      'form-webengine.maxFramerate'
                                    )
                                  "
                                  data-vv-name="maxFramerate"
                                  v-validate="
                                    'isNumerical|min_value:0|max_value:60'
                                  "
                                  data-vv-validate-on="change"
                                  data-testid="max-frame-rate"
                                />
                              </div>
                              <div>
                                <v-combobox
                                  v-model="activeWebEngine.source.ptime"
                                  :items="ptimeItems"
                                  outlined
                                  persistent-hint
                                  :error-messages="
                                    errors.collect('form-webengine.ptime')
                                  "
                                  label="Ptime"
                                  data-vv-name="ptime"
                                  data-vv-validate-on="change"
                                  data-vv-as="Ptime"
                                  v-validate="'numeric'"
                                  dense
                                  style="max-width: 180px"
                                  hint="Duration of a audio packet"
                                  suffix="ms"
                                  class="mx-2 pb-3 active-label"
                                  data-testid="ptime"
                                />
                              </div>
                              <div>
                                <v-combobox
                                  v-model="activeWebEngine.source.sampleRate"
                                  :items="sampleRateItems"
                                  outlined
                                  persistent-hint
                                  :error-messages="
                                    errors.collect('form-webengine.sampleRate')
                                  "
                                  label="Sample Rate"
                                  data-vv-name="sampleRate"
                                  data-vv-validate-on="change"
                                  data-vv-as="Sample Rate"
                                  dense
                                  style="max-width: 180px"
                                  hint="Audio samples per second"
                                  :min="0"
                                  class="mx-1 pb-3 active-label"
                                  v-validate="'numeric'"
                                  data-testid="sample-rate"
                                />
                              </div>
                              <div>
                                <v-combobox
                                  v-model="activeWebEngine.source.sampleSize"
                                  :items="sampleSizeItems"
                                  outlined
                                  persistent-hint
                                  :error-messages="
                                    errors.collect('form-webengine.sampleSize')
                                  "
                                  label="Sample size"
                                  data-vv-name="sampleSize"
                                  data-vv-validate-on="change"
                                  data-vv-as="Sample Size"
                                  dense
                                  style="max-width: 160px"
                                  hint="Bits per sample"
                                  :min="0"
                                  class="mx-1 pb-3 active-label"
                                  suffix="bits"
                                  v-validate="'numeric'"
                                  data-testid="sample-size"
                                />
                              </div>
                              <div>
                                <v-combobox
                                  v-model="
                                    activeWebEngine.source.maxAverageBitrate
                                  "
                                  :items="maxAverageBitrateItems"
                                  outlined
                                  label="Max Audio Bitrate"
                                  hint="Max Audio Bitrate"
                                  persistent-hint
                                  :error-messages="
                                    errors.collect(
                                      'form-webengine.maxAverageBitrate'
                                    )
                                  "
                                  data-vv-name="maxAverageBitrate"
                                  class="mx-2 pb-3 active-label"
                                  :min="0"
                                  dense
                                  style="max-width: 190px"
                                  suffix="kbps"
                                  v-validate="'numeric'"
                                  data-testid="max-audio-bitrate"
                                />
                              </div>
                              <div>
                                <v-checkbox
                                  v-model="
                                    activeWebEngine.source.autoGainControl
                                  "
                                  label="autoGainControl"
                                  class="mx-2 mt-1"
                                  dense
                                  data-testid="chbx-auto-gain-control"
                                />
                              </div>
                              <div>
                                <v-checkbox
                                  v-model="
                                    activeWebEngine.source.echoCancellation
                                  "
                                  label="echoCancellation"
                                  class="mx-2 mt-1"
                                  dense
                                  data-testid="chbx-echo-cancellation"
                                />
                              </div>
                              <div>
                                <v-checkbox
                                  v-model="
                                    activeWebEngine.source.noiseSuppression
                                  "
                                  label="noiseSuppression"
                                  class="mx-2 mt-1"
                                  dense
                                  data-testid="chbx-noise-suppression"
                                />
                              </div>
                              <div>
                                <v-checkbox
                                  v-model="doStreamRestart"
                                  label="Stream restart"
                                  class="mx-2 mt-1"
                                  dense
                                  data-testid="chbx-stream-restart"
                                />
                              </div>
                              <div>
                                <v-checkbox
                                  v-model="excludeSenderParams"
                                  label="Exclude params"
                                  class="mx-2 mt-1"
                                  dense
                                  data-testid="chbx-exclude-params"
                                />
                              </div>
                            </fieldset>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          data-testid="container-available-servers"
                        >
                          <div
                            class="px-4 py-2"
                            data-testid="title-available-servers"
                          >
                            Available stream servers
                          </div>
                          <div class="d-inline-flex">
                            <v-select
                              outlined
                              v-model="activeWebEngine.remote.turn_server_key"
                              :items="modelWebengine.listRemoteTurns"
                              item-text="data.name"
                              item-value="_key"
                              :menu-props="{
                                maxHeight: '132',
                                maxWidth: '300',
                              }"
                              class="mx-2"
                              label="Turn server"
                              hint="Turn server for preview remote stream"
                              persistent-hint
                              :error-messages="
                                errors.collect('form-webengine.turnRemote')
                              "
                              data-vv-name="turnRemote"
                              dense
                              data-testid="remote-turn-server"
                            />
                            <v-checkbox
                              v-model="activeWebEngine.remote.force_stop"
                              label="Force turn"
                              class="mx-2 mt-1"
                              dense
                              data-testid="chbx-remote-force-turn"
                            />
                          </div>
                          <div class="px-4 py-2">
                            Turn server for local stream
                          </div>
                          <div class="d-inline-flex">
                            <v-select
                              outlined
                              v-model="activeWebEngine.source.turn_server_key"
                              :items="modelWebengine.listLocalTurns"
                              item-text="data.name"
                              item-value="_key"
                              :menu-props="{
                                maxHeight: '132',
                                maxWidth: '300',
                              }"
                              class="mx-2"
                              label="Turn server"
                              hint="Turn server for local stream"
                              persistent-hint
                              :error-messages="
                                errors.collect('form-webengine.turnLocal')
                              "
                              data-vv-name="turnLocal"
                              dense
                              data-testid="local-turn-server"
                            />
                            <v-checkbox
                              v-model="activeWebEngine.source.force_stop"
                              label="Force turn"
                              class="mx-2 mt-1"
                              dense
                              data-testid="chbx-local-force-turn"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <!-- Advanced Settings End -->
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import GeneralMixin from "@/mixins/general.js"
import WECommonMixin from "@/mixins/webengine/common"
import GeneralService from "@/common/general.service"
import RemoteAudioChannels from "@/components/engines/webengines/RemoteAudioChannels"

export default {
  name: "web-engine-form",

  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    RemoteAudioChannels,
  },

  mixins: [GeneralMixin, WECommonMixin],

  data() {
    return {
      previewAspxStreams: undefined,
      aspxStreamsTable: {
        search: "",
        pagination: {
          sortBy: ["name"],
          rowsPerPage: -1,
        },
        selected: [],
        headers: [
          {
            text: "Preset",
            align: "left",
            value: "preset_name",
          },
        ],
        loading: false,
        data: [],
      },
      availableStreams: {
        search: "",
        pagination: {
          sortBy: ["name"],
          rowsPerPage: -1,
        },
        selected: [],
        headers: [
          {
            text: "Preset",
            align: "left",
            value: "preset_name",
          },
        ],
        loading: false,
        data: [],
      },
      model_webengine: {
        name: "",
        input_stream: null,
        common_room: false,
        turn_local: null,
        turn_remote: null,
        force_turn_local: false,
        force_turn_remote: false,
        selected_signal: null,
        list_signals: [],
        bandwidth_list: [
          {
            id: "unlimited",
            name: "unlimited",
          },
          {
            id: "2000",
            name: "2000",
          },
          {
            id: "1000",
            name: "1000",
          },
          {
            id: "500",
            name: "500",
          },
          {
            id: "250",
            name: "250",
          },
          {
            id: "125",
            name: "125",
          },
          {
            id: "50",
            name: "50",
          },
        ],
      },
      panel: [1],
      tempBlock: false,
    }
  },

  methods: {
    // getMinFramerateRanges(type) {
    //   if (type == 'max') {
    //     return this.activeWebEngine.source.max_framerate == 0 ? 60 : this.activeWebEngine.source.max_framerate;
    //   }
    // },
    startPreviewAspxStream() {
      this.$emit("preview-aspx-stream", this.activeWebEngine.remote.stream)
    },
    startLocalStream() {
      let self = this
      self.$validator.validateAll("form-webengine").then((result) => {
        if (result) {
          self.$emit("start-local-stream")
        }
      })
    },
    stopLocalStream() {
      this.$emit("stop-local-stream")
    },
    runAllStreams() {
      this.$emit("run-all-streams")
    },
    onChangeResolution(newValue) {
      let self = this
      self.$store
        .dispatch("saveVideoResolution", {
          width: newValue.width,
          height: newValue.height,
        })
        .then(() => {
          self.runAllStreams()
        })
    },
    startWebrtcScanDevices() {
      this.$store.dispatch("setDialogWebrtcSourcesStatus", true)
    },
  },
  computed: {
    ...mapGetters({
      allVideoSources: "getAllVideoSources",
      allAudioSources: "getAllAudioSources",
      mediaConstraints: "getMediaConstraints",
      roomMembers: "getRoomMembersWsConn",
      signalRoomMembersSenders: "signalRoomMembersSenders",
      webengineSendersSignal: "webengineSendersSignal",
      modelWebengine: "getModelWebengine",
      isLocalStreamStarted: "getIsLocalStreamStarted",
      isPreviewRemoteStreamStarted: "getIsPreviewRemoteStreamStarted",
      activeWebEngine: "getActiveWebEngine",
      listWebcameraResolutions: "listWebcameraResolutions",
      resolutionString: "getResolutionString",
      getGuestLink: "getGuestLink",
      errorsLocalStream: "errorsLocalStream",
      errorsRemoteStream: "errorsRemoteStream",
      signalServersList: "signalServersList",
    }),

    selectedSignal: {
      get() {
        return this.$store.getters.getActiveSignalServerKey
      },
      set(newValue) {
        this.$store.dispatch("webenginesSaveSelectedSignal", newValue)
      },
    },

    selectedAspxStream: {
      get() {
        return this.$store.getters.getSelectedAspxStreamTableData
      },
      set(newValue) {
        // do nothing
      },
    },

    selectedVideoSource: {
      get() {
        return this.$store.getters.getSelectedVideoSourceWebengine({
          getDefaultValue: this.editedIndex === -1,
        })
      },
      set(newValue) {
        this.$store.dispatch("saveVideoInputMediaConstraints", {
          [GeneralService.getBrowserName()]: newValue,
        })
      },
    },

    selectedAudioSource: {
      get() {
        return this.$store.getters.getSelectedAudioSourceWebengine({
          getDefaultValue: this.editedIndex === -1,
        })
      },
      set(newValue) {
        this.$store.dispatch("saveAudioInputMediaConstraints", {
          [GeneralService.getBrowserName()]: newValue,
        })
      },
    },

    selectedResolution: {
      get() {
        return this.$store.getters.getSelectedResolutionWebengine
      },
      set(newValue) {
        this.$store.dispatch("saveVideoResolution", {
          width: newValue.width,
          height: newValue.height,
        })
      },
    },

    noSourceToStart: function () {
      return (
        _.isNull(this.selectedVideoSource) && _.isNull(this.selectedAudioSource)
      )
    },

    canGenerateEngineLinkComputed() {
      if (this.activeWebEngine.name !== "" && this.editedIndex !== -1)
        return false
      return true
    },

    haveEngineNameComputed() {
      if (this.activeWebEngine.name === "") return true
      return false
    },
    maxAverageBitrateItems() {
      let defaultArr = [520, 260]
      return this.activeWebEngine.source.maxAverageBitrate
        ? [
            ...defaultArr,
            ...[this.activeWebEngine.source.maxAverageBitrate].filter(
              (a) => !defaultArr.includes(a)
            ),
          ]
        : defaultArr
    },
    sampleSizeItems() {
      let defaultArr = [24, 16, 8]
      return this.activeWebEngine.source.sampleSize
        ? [
            ...defaultArr,
            ...[this.activeWebEngine.source.sampleSize].filter(
              (a) => !defaultArr.includes(a)
            ),
          ]
        : defaultArr
    },
    sampleRateItems() {
      let defaultArr = [192000, 96000, 48000, 44100]
      return this.activeWebEngine.source.sampleSize
        ? [
            ...defaultArr,
            ...[this.activeWebEngine.source.sampleSize].filter(
              (a) => !defaultArr.includes(a)
            ),
          ]
        : defaultArr
    },
    maxFramerateItems() {
      let defaultArr = [30, 15, 60]
      return this.activeWebEngine.source.maxFramerate
        ? [
            ...defaultArr,
            ...[this.activeWebEngine.source.maxFramerate].filter(
              (a) => !defaultArr.includes(a)
            ),
          ]
        : defaultArr
    },
    ptimeItems() {
      let defaultArr = [3, 5, 10, 20, 40, 60, 120]
      return this.activeWebEngine.source.maxFramerate
        ? [
            ...defaultArr,
            ...[this.activeWebEngine.source.maxFramerate].filter(
              (a) => !defaultArr.includes(a)
            ),
          ]
        : defaultArr
    },
    doStreamRestart: {
      get() {
        return this.$store.getters.doStreamRestart
      },
      set(val) {
        this.$store.dispatch("saveDoStreamRestart", val)
      },
    },
    excludeSenderParams: {
      get() {
        return this.$store.getters.excludeSenderParams
      },
      set(val) {
        this.$store.dispatch("saveExcludeSenderParams", val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/engine/webengine/webengine-form.scss";
@import "@/assets/scss/components/scroll.scss";
</style>

<style lang="scss" scoped>
video {
  pointer-events: none;
}

//.btn-rescan-select {
//  background: green;
//}

.fieldset-settings {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-color: #424242;
  border-radius: 8px;

  .legend-settings {
    padding: 0 3px;
    margin-left: 7px;
    background-color: transparent;
    color: #424242;
    font-weight: bold;
  }
}
</style>

<style lang="scss">
.active-label {
  legend {
    width: min-content !important;
  }

  .v-label {
    transform: translateY(-16px) scale(0.75) !important;
    background-color: #000;
    padding: 0 4px;
    margin-left: -4px;
  }
}

.full-width-snackbar .v-snack__wrapper {
  max-width: none;
  width: 100%;
}
</style>
