<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    dark
    persistent
  >
    <v-card v-if="dataLoaded" data-testid="container-webengine">
      <FirefoxUseAlert wrapper-class="mx-4 mt-2" alert-class="mb-0" />

      <v-card-title class="webEngineDialogMainTitle">
        <span
          class="subtitle-1"
          v-html="getTitle()"
          data-testid="title-webengine"
        ></span>
      </v-card-title>

      <web-engine-form
        @preview-aspx-stream="startPreviewAspxStream"
        @start-local-stream="startLocalStream"
        @stop-local-stream="stopLocalStream"
        @restart-webrtc-conns="restartWebrtcConns"
        @start-active-remote-stream="startActiveRemoteStream"
        @close-dialog="closeDialog"
        ref="formPage"
        :editedIndex="editedIndex"
        @run-all-streams="runAllStreams"
      />

      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <!--<v-btn dark text color="primary" @click="receiver.getAspxStreamsList()">-->
        <!--Refresh streams list-->
        <!--</v-btn>-->

        <v-btn
          dark
          text
          color="error"
          class="ml-1"
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>

        <v-btn
          dark
          text
          color="green"
          class="ml-1"
          @click="goStreamingPage"
          data-testid="btn-save-start"
        >
          Save & Start
          <v-icon dark>mdi-play</v-icon>
        </v-btn>

        <v-btn
          dark
          text
          color="orange"
          class="ml-1"
          @click="save()"
          data-testid="btn-save-close"
        >
          <!--v-if="$can('update', 'Engines')"-->
          Save & close
          <v-icon dark class="pl-1">mdi-content-save-move</v-icon>
        </v-btn>

        <!--        <v-btn dark text color="green" class="ml-1" @click="$store.dispatch('resetAllWebrtcSources')">-->
        <!--          RESET SOURCES-->
        <!--        </v-btn>-->
      </v-card-actions>
    </v-card>

    <web-engine-dialog-sources
      v-if="initialised && !showLoader"
      @close-dialog="closeDialog"
      :selectiveScan.sync="selectiveScan"
      @finished-selective-scan="initAfterSourcesScan"
      @cancel-selective-scan="initAfterSourcesScan"
    />

    <confirm ref="confirmSaveWebengine"></confirm>

    <web-engine-minimal-settings
      :dialog.sync="dialogMinimalSettings"
      @save="saveAfterInitialSettings()"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash"
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import WebEngineForm from "./WebEngineForm"
import WECommonMixin from "@/mixins/webengine/common"
import WebEngineDialogSources from "./WebEngineDialogSources"
import Confirm from "@/components/Confirm"
import WebEngineMinimalSettings from "./WebEngineMinimalSettings"
import FirefoxUseAlert from "@/components/engines/FirefoxUseAlert"

export default {
  name: "web-engine-dialog",
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },
  components: {
    WebEngineForm,
    WebEngineDialogSources,
    Confirm,
    WebEngineMinimalSettings,
    FirefoxUseAlert,
  },
  mixins: [GeneralMixin, WECommonMixin],

  data() {
    return {
      closeDlg: true,
      initiatedSignalConns: false,
      dialog: true,
      initialised: false,
      dialogMinimalSettings: false,
    }
  },

  watch: {
    activeSignalServerUrl: function (newVal, oldVal) {
      let self = this
      if (newVal && self.sender && self.receiver) {
        let pr1 = self.sender.onSignalUrlChangeCommon(newVal, oldVal)
        let pr2 = self.receiver.onSignalUrlChangeCommon(newVal, oldVal)
        Promise.all([pr1, pr2]).then(() => {
          self.restartWebrtcConns()
        })
      }
    },

    activeSignalRoom: function (newVal, oldVal) {
      let self = this
      if (oldVal && self.sender && self.receiver) {
        let pr1 = self.sender.onRoomChangeCommon(newVal, oldVal)
        let pr2 = self.receiver.onRoomChangeCommon(newVal, oldVal)
        Promise.all([pr1, pr2]).then(() => {
          self.runAllStreams()
        })
      }
    },

    "activeWebEngine.remote.bandwidth": {
      handler: _.debounce(function (newVal, oldVal) {
        if (oldVal !== newVal && this.sender && this.receiver) {
          this.sender.props.bandwidth = parseFloat(newVal)
          this.receiver.props.bandwidth = parseFloat(newVal)
          this.sender.onBandwidthChange(newVal)
        }
      }, 1000),
      deep: true,
    },

    isPreviewRemoteStreamStarted: function (newVal) {
      if (newVal) {
        this.sender.onBandwidthChange(this.activeWebEngine.remote.bandwidth)
      }
    },

    signalRoomMembersSenders: {
      handler: _.debounce(function (newVal, oldVal) {
        this.updateSendersList(newVal, oldVal)
      }, 1500),
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.$store.dispatch("saveLoaderAction", true)
  },

  mounted: function () {
    let self = this
    // @todo hack for dialog with WebRTC sources init to be over other dialogs
    setTimeout(() => {
      self.initialised = true
    }, 400)
    document.querySelector("html").style.overflow = "hidden"
    self.$store.dispatch("currentUserCompanyInfo").then(() => {
      self.$store.dispatch("webenginesResetData").then(() => {
        self.initDialogData().then(() => {
          if (!self.isWebrtcSourcesInitialised) {
            self.selectiveScan = true
            self.$store.dispatch("saveLoaderAction", false).then(() => {
              self.$store.dispatch("setDialogWebrtcSourcesStatus", true)
            })
          } else {
            self.initAfterSourcesScan()
          }
        })
      })
    })
  },

  beforeDestroy() {
    document.querySelector("html").style.overflow = null
    this.onDestroyPage()
  },

  methods: {
    initAfterSourcesScan() {
      let self = this
      self.$store.dispatch("saveLoaderAction", true).then(() => {
        self.$store.dispatch("initSelectedVideoWebrtcSource", {
          getDefaultValue: this.editedIndex === -1,
        })
        self.$store.dispatch("initSelectedAudioWebrtcSource", {
          getDefaultValue: this.editedIndex === -1,
        })
        self.$store.dispatch("initSelectedSignalKey").then(() => {
          self.allSignalsInitialisations().then(() => {
            self.$store.dispatch("saveLoaderAction", false)
          })
          window.onbeforeunload = function () {
            self.onDestroyPage()
            return
          }
          this.getEngineGuestLink()
          if (self.editedIndex === -1) self.dialogMinimalSettings = true
        })
      })
    },

    allSignalsInitialisations() {
      let self = this
      return new Promise((resolve) => {
        if (self.editedIndex !== -1) {
          self.createSignalConnections().then(() => {
            self.runAllStreams()
            resolve()
          })
        } else {
          resolve()
          // navigator.mediaDevices.getUserMedia({video: false, audio: false})
          //   .catch(err => {
          //     resolve({error: err})
          //   })
          //   .then(function (localStream) {
          //     navigator.mediaDevices.enumerateDevices()
          //       .then(self.gotDevices).catch(self.handleGetUserMediaError).then(() => {
          //       resolve()
          //     })
          //   })
        }
      })
    },

    handleGetUserMediaError(error) {
      console.log(
        "navigator.MediaDevices.getUserMedia error: ",
        error?.message,
        error?.name
      )
    },

    getEngineGuestLink() {
      if (this.activeWebEngine._key) {
        this.$store
          .dispatch("webengineGetLink", {
            web_engine_key: this.activeWebEngine._key,
          })
          .then((response) => {
            if (response.data.data.link_hash) {
              this.$store.commit("SET_GUEST_LINK", response.data.data.link_hash)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    restartWebrtcConns() {
      let self = this
      self.$store.commit("RESET_LIST_MEMBERS_WS_CONN")
      let localClose = self.sender.disconnect()
      let remoteClose = self.receiver.disconnect()

      Promise.all([localClose, remoteClose]).then((values) => {
        let beforeIsLocalStreamStarted = self.isLocalStreamStarted
        self.sender.getOrCreateConnection().then(function () {
          if (beforeIsLocalStreamStarted) {
            self.startLocalStream()
          }
        })

        self.receiver.getOrCreateConnection().then(function () {
          self.receiver.getAspxStreamsList()
        })
      })
    },

    startActiveRemoteStream() {
      let self = this
      self.sender.getOrCreateConnection().then((lConn) => {
        let selectedStream = self.activeWebEngine.remote.stream
        self.startPreviewAspxStream(selectedStream)
      })
    },

    getTitle() {
      return this.editedIndex == -1
        ? "Add new Web Engine"
        : `<span class="blue-grey--text text--lighten-4">Edit Web Engine</span> ${this.activeWebEngine.name}`
    },

    goStreamingPage() {
      let self = this
      self.save(false).then((key) => {
        self.stopLocalStream().then(() => {
          self.stopPreviewAspxStream().then(() => {
            setTimeout(() => {
              self.$router
                .push(`/engines/webengine/streaming/${key}`)
                .catch((err) => {})
            }, 500)
          })
        })
      })
    },

    saveAfterInitialSettings() {
      let self = this
      self.save(false, false).then((key) => {
        setTimeout(() => {
          self.$router
            .push(`/engines/webengine/edit/${key}`)
            .then(() => {
              self.dialogMinimalSettings = false
            })
            .catch((err) => {})
        }, 500)
      })
    },

    createWebEngine(formData, close, showSnackbar) {
      let self = this
      return new Promise((resolve, reject) => {
        self.$validator.errors.clear("form-webengine")
        self.$refs.formPage.$validator
          .validateAll("form-webengine")
          .then((result) => {
            if (result) {
              self.$store
                .dispatch("webenginesCreateAction", formData)
                .then(({ data }) => {
                  this.$emit("changed-edited-index", data.data.web_engine._key)
                  self.$emit("data-saved")
                  if (close) self.closeDialog()
                  if (showSnackbar) {
                    let params = {
                      text:
                        "Web Engine " +
                        self.activeWebEngine.name +
                        " was created successfully.",
                      show: true,
                    }
                    self.$root.$emit("snackbar", params)
                  }
                  resolve(data)
                })
                .catch((error) => {
                  self.handleValidationErrors(error.response, "form-webengine")
                  console.log("error on createWebEngine", error.response)
                  reject()
                })
            } else {
              reject()
            }
          })
      })
    },

    editWebEngine(data, close, showSnackbar) {
      let self = this
      return new Promise((resolve, reject) => {
        self.$validator.errors.clear("form-webengine")
        self.$refs.formPage.$validator
          .validateAll("form-webengine")
          .then((result) => {
            if (result) {
              self.$store
                .dispatch("webenginesEditAction", data)
                .then(({ data }) => {
                  self.$emit("data-saved")
                  if (close) self.closeDialog()
                  if (showSnackbar) {
                    let params = {
                      text:
                        "Web Engine " +
                        self.activeWebEngine.name +
                        " was edited successfully.",
                      show: true,
                    }
                    self.$root.$emit("snackbar", params)
                  }
                  resolve(data)
                })
                .catch((error) => {
                  console.log("error on editWebEngine", error.response)
                  self.handleValidationErrors(error.response, "form-webengine")
                  reject()
                })
            } else {
              reject()
            }
          })
      })
    },

    save(close = true, showSnackbar = true) {
      let self = this
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // timeout is set for save combobox search value on blur, before save
          let formData = {
            data: {
              name: self.activeWebEngine.name,
              signal_server_key: self.activeWebEngine.signal_server_key,
              common_room: self.activeWebEngine.common_room,
              is_active: self.activeWebEngine.is_active,
              source: {
                video_source: self.activeWebEngine.source.video_source,
                audio_source: self.activeWebEngine.source.audio_source,
                // min_framerate: parseInt(self.activeWebEngine.source.min_framerate),
                // max_framerate: parseInt(self.activeWebEngine.source.max_framerate),
                turn_server_key: self.activeWebEngine.source.turn_server_key,
                force_stop: self.activeWebEngine.source.force_stop,
                stream_type: self.activeWebEngine.source.stream_type,
                resolution: self.activeWebEngine.source.resolution,
                video_codec: self.activeWebEngine.source.video_codec,
                maxFramerate: parseFloat(
                  self.activeWebEngine.source.maxFramerate
                ),
                ptime: parseInt(self.activeWebEngine.source.ptime),
                autoGainControl: self.activeWebEngine.source.autoGainControl,
                echoCancellation: self.activeWebEngine.source.echoCancellation,
                noiseSuppression: self.activeWebEngine.source.noiseSuppression,
                sampleRate: parseFloat(self.activeWebEngine.source.sampleRate),
                sampleSize: parseFloat(self.activeWebEngine.source.sampleSize),
                maxAverageBitrate: parseInt(
                  self.activeWebEngine.source.maxAverageBitrate
                ),
              },
              remote: {
                stream: self.activeWebEngine.remote.stream
                  ? _.omit(self.activeWebEngine.remote.stream, [
                      "token",
                      "loginHash",
                    ])
                  : null,
                turn_server_key: self.activeWebEngine.remote.turn_server_key,
                force_stop: self.activeWebEngine.remote.force_stop,
                bandwidth: self.activeWebEngine.remote.bandwidth,
              },
            },
          }

          let prms = null
          if (this.editedIndex > -1) {
            formData["slug"] = this.editedIndex
            prms = self.editWebEngine(formData, close, showSnackbar)
          } else {
            prms = self.createWebEngine(formData, close, showSnackbar)
          }
          prms
            .then((data) => {
              resolve(_.get(data, "data.web_engine._key"))
            })
            .catch(() => {
              console.error("Error on create webengine")
            })
        }, 500)
      })
    },
  },

  computed: {
    ...mapGetters({
      isLocalStreamStarted: "getIsLocalStreamStarted",
      isPreviewRemoteStreamStarted: "getIsPreviewRemoteStreamStarted",
      activeWebEngine: "getActiveWebEngine",
      activeSignalServerUrl: "getActiveSignalServerUrl",
      activeSignalRoom: "getActiveSignalRoom",
      activeLocalTurnData: "getActiveLocalTurnData",
      activeRemoteTurnData: "getActiveRemoteTurnData",
      signalRoomMembersSenders: "signalRoomMembersSenders",
      showLoader: "getShowLoader",
      isVideoWebrtcSourcesInitialised: "isVideoWebrtcSourcesInitialised",
      dialogWebrtcSources: "dialogWebrtcSources",
      getGuestLink: "getGuestLink",
      isWebrtcSourcesInitialised: "isWebrtcSourcesInitialised",
    }),

    dataLoaded: function () {
      return (
        (this.editedIndex !== -1 && this.initiatedSignalConns) ||
        this.editedIndex === -1
      )
    },

    selectedVideoSource: function () {
      return this.$store.getters.getSelectedVideoSourceWebengine({
        getDefaultValue: this.editedIndex === -1,
      })
    },

    selectedAudioSource: function () {
      return this.$store.getters.getSelectedAudioSourceWebengine({
        getDefaultValue: this.editedIndex === -1,
      })
    },

    noSourceToStart: function () {
      return (
        _.isNull(this.selectedVideoSource) && _.isNull(this.selectedAudioSource)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/scroll.scss";
@import "@/assets/scss/components/engine/webengine/webengine-common.scss";
</style>
