<template>
  <div :class="wrapperClass" data-testid="container-use-latest-firefox-browser">
    <v-alert
      v-show="!isFirefoxBrowser"
      :text="text"
      :outlined="outlined"
      dense
      type="warning"
      border="left"
      dismissible
      icon="mdi-alert-circle-outline"
      :class="alertClass"
      data-testid="notification-use-latest-firefox-browser"
    >
      Please use latest Firefox Browser for best results.
    </v-alert>
  </div>
</template>

<script>
import adapter from "webrtc-adapter"

export default {
  name: "FirefoxUseAlert",

  props: {
    text: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    alertClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      audioChannels: [
        {
          key: 0,
          muted: true,
        },
        {
          key: 1,
          muted: false,
        },
      ],
    }
  },

  computed: {
    isFirefoxBrowser() {
      return adapter.browserDetails.browser === "firefox"
    },
  },
}
</script>
