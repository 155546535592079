<template>
  <v-dialog
    dark
    max-width="600"
    v-model="dialog"
    persistent
    key="key-dialog-webengine-minimal-settings"
    :transition="false"
  >
    <v-card data-testid="container-webengine-minimal-settings">
      <v-form
        data-vv-scope="form-webengine-minimal-settings"
        data-testid="form-webengine-minimal-settings"
      >
        <v-card-title>
          <span class="headline" data-testid="title-webengine-minimal-settings">
            Initial settings
          </span>
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  ref="name"
                  v-model="activeWebEngine.name"
                  :error-messages="
                    errors.collect('form-webengine-minimal-settings.name')
                  "
                  label="Event name"
                  placeholder=""
                  data-vv-name="name"
                  data-vv-validate-on="change"
                  data-vv-as="Event name"
                  outlined
                  single-line
                  v-validate="'required|max:50'"
                  dense
                  data-testid="name"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.native="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import WECommonMixin from "@/mixins/webengine/common"

export default {
  name: "web-engine-minimal-settings",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [WECommonMixin],

  methods: {
    save() {
      let self = this
      self.$validator.errors.clear("form-webengine-minimal-settings")
      self.$validator
        .validateAll("form-webengine-minimal-settings")
        .then((result) => {
          if (result) {
            self.$emit("save")
          }
        })
    },
  },
  computed: {
    ...mapGetters({
      activeWebEngine: "getActiveWebEngine",
    }),
  },
}
</script>
